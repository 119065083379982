import { useEffect, useState } from 'react';

export default function useViewCount(id) {
  const [count, setCount] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetch(`/.netlify/functions/get-views?id=${id}`)
      .then((response) => response.json())
      .then((data) => {
        setCount(data.count || 0);
      })
      .catch((err) => {
        setError(err);
      });
  }, [id]);

  return [count, error];
}
