/** @jsx jsx */
import { jsx } from 'theme-ui';

import ArticleCard from './ArticleCard';

const StickyArticle = ({ post }) => (
  <div
    sx={{
      position: 'sticky',
      top: (t) => t.sizes.headerHeight,
      '@media (max-width: 1630px)': {
        display: 'none',
      },
    }}
  >
    <ArticleCard
      post={post}
      showTools={false}
      sx={{
        width: 288,
        minHeight: 256,
      }}
    />
  </div>
);

export default StickyArticle;
