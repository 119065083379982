/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Fragment, useEffect, useState } from 'react';
import { withPrefix } from 'gatsby';

import copy from 'copy-to-clipboard';
import { useLocation } from '@reach/router';

import useViewCount from '../hooks/use-view-count';

import ClockIcon from '../img/icons/clock.inline.svg';
import EyeIcon from '../img/icons/eye.inline.svg';
import ForwardIcon from '../img/icons/forward.inline.svg';
import LinkIcon from '../img/icons/link.inline.svg';

import Icon from '../components/Icon';
import { IconButton } from '../components/Buttons';

const ArticleTools = ({ post }) => {
  const [views] = useViewCount(post.slug);
  const { origin } = useLocation();

  const path = post.fields.url;
  const url = `${origin}${withPrefix(path)}`;

  const [canShare, setCanShare] = useState(false);

  useEffect(() => {
    setCanShare(!!window.navigator.share);
  }, []);

  return (
    <Fragment>
      <div sx={{ display: 'grid', gridAutoFlow: 'column', gridGap: 2, mr: 1 }}>
        <div sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon icon={ClockIcon} />
          {post.timeToRead}'
        </div>
        <div sx={{ display: 'flex', alignItems: 'center' }}>
          <Icon icon={EyeIcon} />
          {views}
        </div>
      </div>

      <div sx={{ display: 'grid', gridAutoFlow: 'column', gridGap: 2, ml: 1 }}>
        {canShare && (
          <IconButton
            icon={ForwardIcon}
            onClick={() => {
              window.navigator.share({
                title: post.frontmatter.title,
                url,
              });
            }}
          />
        )}
        <IconButton
          icon={LinkIcon}
          title="Copy to clipboard"
          onClick={() => {
            copy(url, {
              format: 'text/plain',
            });
          }}
        />
      </div>
    </Fragment>
  );
};

export default ArticleTools;
