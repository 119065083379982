/** @jsx jsx */
import { jsx } from 'theme-ui';

import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { resetLink } from '../utils/styles';
import { getTypeLabel } from '../utils/articles';
import useCurrentLanguage from '../hooks/use-current-language';

import ArticleTools from '../components/ArticleTools';

const ArticleCard = ({ post, showTools, ...props }) => {
  const lang = useCurrentLanguage();
  const path = post.fields.url;

  return (
    <article
      {...props}
      title={post.frontmatter.title}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        bg: 'white',
        borderRadius: '4px',
      }}
    >
      <Link
        to={path}
        sx={{
          ...resetLink,
          flexGrow: 1,
          bg: 'gray1',
          borderTopLeftRadius: '4px',
          borderTopRightRadius: '4px',
        }}
      >
        {post.frontmatter.thumbnail && (
          <Img
            fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
            loading="eager"
            fadeIn={false}
            sx={{ mb: 5 }}
          />
        )}
        <div
          sx={{
            pl: 4,
            fontSize: '12px',
            textTransform: 'uppercase',
          }}
        >
          {getTypeLabel(post.fields.sourceInstanceName, lang)}
        </div>
        <div
          sx={{
            p: 4,
            display: 'block',
            fontWeight: 'bold',
            'a:hover &': {
              color: 'secondary',
            },
          }}
        >
          {post.frontmatter.title}
          {post.frontmatter.subtitle && (
            <div
              sx={{
                fontWeight: 'normal',
              }}
            >
              {post.frontmatter.subtitle}
            </div>
          )}
        </div>
      </Link>
      {showTools && (
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            p: 1,
          }}
        >
          <ArticleTools post={post} />
        </div>
      )}
    </article>
  );
};

ArticleCard.defaultProps = {
  showTools: true,
};

export default ArticleCard;
