/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';
import useCurrentLanguage from '../hooks/use-current-language';

import headerSolutionsSvg from '../img/headers/header-solutions.svg';
import solutionsModuleDiscoverySvg from '../img/illustrations/solutions-module-discovery.svg';
import solutionsModuleDevelopSvg from '../img/illustrations/solutions-module-develop.svg';
import solutionsModuleDecisionSvg from '../img/illustrations/solutions-module-decision.svg';
import solutionsModuleDecisionFrSvg from '../img/illustrations/solutions-module-decision-fr.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import { Button } from '../components/Buttons';
import StickyArticle from '../components/StickyArticle';
import MiniArticleList from '../components/MiniArticleList';
import AspectRatioImg from '../components/AspectRatioImg';

const imageMap = {
  discovery: solutionsModuleDiscoverySvg,
  develop: solutionsModuleDevelopSvg,
  decision: solutionsModuleDecisionSvg,
  'decision-fr': solutionsModuleDecisionFrSvg,
};

const Related = ({ relatedPosts }) =>
  relatedPosts.length > 0 ? (
    <Container sx={{ mt: 14 }}>
      <MiniArticleList posts={relatedPosts} />
    </Container>
  ) : null;

const ModuleIllustration = ({ imageKey }) => (
  <div sx={{ display: ['none', null, 'block'] }}>
    <AspectRatioImg src={imageMap[imageKey]} alt="" ratio={440 / 429} />
  </div>
);

const ModuleContent = ({ get, module }) => (
  <div>
    <Styled.h3>{get(module, 'title')}</Styled.h3>
    <Styled.p sx={{ mt: 4, fontWeight: 'bold' }}>
      {get(module, 'subtitle')}
    </Styled.p>
    <MarkdownContent sx={{ mt: 7 }}>{get(module, 'text')}</MarkdownContent>
    <Button
      to={get(module, 'cta.path')}
      variant="secondary"
      sx={{
        maxWidth: [null, null, '280px'],
        mt: 7,
      }}
      outlined
    >
      {get(module, 'cta.label')}
    </Button>
  </div>
);

export const SolutionsPageTemplate = ({ get, relatedPosts, featuredPost }) => (
  <Fragment>
    <section>
      <Container
        sidebar={
          featuredPost && {
            right: <StickyArticle post={featuredPost} />,
          }
        }
      >
        <AspectRatioImg src={headerSolutionsSvg} alt="" ratio={912 / 256} />
        <Styled.h1 sx={{ mt: 13, pr: 11 }}>{get('title')}</Styled.h1>
        <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
        <div
          sx={{
            display: 'grid',
            gridAutoFlow: 'rows',
            gridGap: 14,
            mt: 13,
          }}
        >
          {get('modules', []).map((module, i) => (
            <article
              key={i}
              sx={{
                display: 'grid',
                gridTemplateColumns: ['1fr', null, '1fr 1fr'],
                gridGap: 7,
              }}
            >
              {i % 2 === 0 ? (
                <Fragment>
                  <ModuleIllustration imageKey={get(module, 'imageKey')} />
                  <ModuleContent get={get} module={module} />
                </Fragment>
              ) : (
                <Fragment>
                  <ModuleContent get={get} module={module} />
                  <ModuleIllustration imageKey={get(module, 'imageKey')} />
                </Fragment>
              )}
            </article>
          ))}
        </div>
      </Container>
    </section>
    <section>
      <Related relatedPosts={relatedPosts} />
    </section>
  </Fragment>
);

const SolutionsPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    relatedPosts,
    featuredPost,
  } = data;
  const lang = useCurrentLanguage();

  return (
    <Layout
      page="solutions"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <SolutionsPageTemplate
        lang={lang}
        get={createGet(frontmatter)}
        relatedPosts={relatedPosts.nodes}
        featuredPost={featuredPost.nodes[0]}
      />
    </Layout>
  );
};

SolutionsPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SolutionsPage;

export const pageQuery = graphql`
  query SolutionsPageQuery(
    $id: String!
    $language: String!
    $currentDate: Date!
  ) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        subtitle
        text
        modules {
          title
          subtitle
          text
          cta {
            label
            path
          }
          imageKey
        }
      }
    }
    relatedPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 2
      filter: {
        fields: { sourceInstanceName: { eq: "blog-posts" } }
        frontmatter: {
          tags: { in: ["solution"] }
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
          sourceInstanceName
        }
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
    featuredPost: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 1
      filter: {
        fields: { sourceInstanceName: { eq: "blog-posts" } }
        frontmatter: {
          tags: { in: ["innovation"] }
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
          sourceInstanceName
        }
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
  }
`;
